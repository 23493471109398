@import "./vendor/styles/_custom-variables/features";

@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~ngx-toastr/toastr';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import './vendor/libs/ngx-datatable/ngx-datatable.scss';
//@import '~@swimlane/ngx-datatable/index.css';
//@import '~@swimlane/ngx-datatable/themes/material.scss';
@import "./vendor/styles/bootstrap-material.scss";
@import "./vendor/styles/appwork-material.scss";
@import "./vendor/styles/theme-soft-material.scss";
@import "./vendor/styles/colors-material.scss";
@import "./vendor/styles/uikit.scss";
@import "./demo.scss";
@import '~cookieconsent/build/cookieconsent.min.css';

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Remove outline on desktops
@media(min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
  overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
  opacity: 1;
  transition: opacity .2s;

  body.app-loading & {
    opacity: .75;
  }
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

html {
  .tooltip,
  .popover {
    animation: ngFloatingElementAnimation .25s;
  }
  .dropdown-menu {
    animation: ngFloatingElementAnimation .1s;
  }
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
  0% { opacity: 0; }
  100% { opacity: .5; }
}
@include keyframes(ngDefaultModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@include keyframes(ngTopModalAnimation) {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@include keyframes(ngSlideModalAnimation) {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@if $enable-rtl-support {
  @include keyframes(ngRtlSlideModalAnimation) {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
@include keyframes(ngFillInModalAnimation) {
  0% {
    opacity: 0;
    transform: scale(.5, .5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: .5;
  animation: ngModalBackdropAnimation .15s ease-out;
}

ngb-modal-window {
  &.modal .modal-dialog {
    animation: ngDefaultModalAnimation .15s ease-out;
  }

  &.modal-top .modal-dialog {
    animation: ngTopModalAnimation .15s ease-out;
  }

  &.modal-slide .modal-dialog {
    animation: ngSlideModalAnimation .15s ease-out;
  }

  &.modal-fill-in .modal-dialog {
    animation: ngFillInModalAnimation .15s ease-out;
  }

  @include feature-rtl {
    &.modal-slide .modal-dialog {
      animation: ngRtlSlideModalAnimation .15s ease-out;
    }
  }
}

@if $enable-material-style {
  @include keyframes(ngMaterialModalBackdropAnimation) {
    0% { opacity: 0; }
    100% { opacity: .2; }
  }

  .material-style ngb-modal-backdrop.modal-backdrop {
    opacity: .2;
    animation: ngMaterialModalBackdropAnimation .15s ease-out;
  }
}

@include feature-rtl(false) {
  // Prevent horizontal scrollbar
  .dz-hidden-input {
    right: 0 !important;
    left: auto !important;
  }
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: .894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

/**
 * Fix ngx-datatable sroll width
 */
datatable-scroller {
    width: 100% !important;
}

/**
 * Set datatable details background
 */
.datatable-row-detail {
    background: #e8e8e8;
    //min-height: 100px !important;
    //max-height: 40vh !important;
    overflow-y: auto !important;
    height: unset !important;
}
